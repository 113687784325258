import styled from 'styled-components';
import { display, sizing, borders, spacing } from '@material-ui/system';

export default styled.img`
  display: block;
  max-width: 100%;
  object-fit: cover;

  ${display}${sizing}${borders}${spacing}
`;
