import { useTheme } from '@material-ui/core/styles';
import { contrastRatio } from '@helpers';

const useColorByBackground = backgroundColor => {
  const theme = useTheme();

  if (
    contrastRatio(theme.colors.white, backgroundColor) >
    contrastRatio(theme.colors.dark, backgroundColor)
  ) {
    return theme.colors.white;
  }

  return theme.colors.dark;
};

export default useColorByBackground;
