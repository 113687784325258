export * from './forms';

/**
 * Calculate the contrast ratio between two colors.
 *
 * Colors should be in rgb() or 3/6-digit hex format; order does not matter
 * (ie. it doesn't matter which is the lighter and which is the darker).
 * Values should be in the range [1.0, 21.0]... a ratio of 1.0 means "they're
 * exactly the same contrast", 21.0 means it's white-on-black or v.v.
 * Formula as per WCAG 2.0 definitions.
 *
 * @param {String} color1 The first color to compare.
 * @param {String} color2 The second color to compare.
 *
 * @returns {Number}
 */
export const contrastRatio = (color1, color2) => {
  if (!color1 || !color2) {
    return 1;
  }

  let ratio = (0.05 + relativeLum(color1)) / (0.05 + relativeLum(color2));
  if (ratio < 1) {
    ratio = 1 / ratio;
  }

  return ratio;
};

/**
 * Calculate relative luminescence for a color in the sRGB color profile.
 *
 * Supports rgb() and hex colors. rgba() also supported but the alpha
 * channel is currently ignored.
 * Hex colors can have an optional "#" at the front, which is stripped.
 * Relative luminescence formula is defined in the definitions of WCAG 2.0.
 * It can be either three or six hex digits, as per CSS conventions.
 * It should return a value in the range [0.0, 1.0].
 *
 * @param {String} color The color to calculate from.
 *
 * @returns {Number}
 */
export const relativeLum = color => {
  if (color.charAt) {
    color = colorStrToRGB(color);
  }

  let transformed = {};
  for (let x in color) {
    if (color[x] <= 0.03928) {
      transformed[x] = color[x] / 12.92;
    } else {
      transformed[x] = Math.pow((color[x] + 0.055) / 1.055, 2.4);
    }
  } //end for

  const lum = transformed.red * 0.2126 + transformed.green * 0.7152 + transformed.blue * 0.0722;
  return lum;
};

/**
 * Convert a color string to a structure with red/green/blue elements.
 *
 * Supports rgb() and hex colors (3 or 6 hex digits, optional "#").
 * rgba() also supported but the alpha channel is currently ignored.
 * Each red/green/blue element is in the range [0.0, 1.0].
 *
 * @param {String} color The color to convert.
 *
 * @returns {Object}
 */
export const colorStrToRGB = color => {
  color = color.toLowerCase();

  if (color.substring(0, 3) === 'rgb') {
    // rgb[a](0, 0, 0[, 0]) format.
    const matches = /^rgba?\s*\((\d+),\s*(\d+),\s*(\d+)([^)]*)\)$/.exec(color);
    color = {
      red: matches[1] / 255,
      green: matches[2] / 255,
      blue: matches[3] / 255,
    };
  } else {
    // Hex digit format.
    if (color.charAt(0) === '#') {
      color = color.substr(1);
    }

    if (color.length === 3) {
      color = color.replace(/^(.)(.)(.)$/, '$1$1$2$2$3$3');
    }

    color = {
      red: parseInt(color.substr(0, 2), 16) / 255,
      green: parseInt(color.substr(2, 2), 16) / 255,
      blue: parseInt(color.substr(4, 2), 16) / 255,
    };
  }

  return color;
};

/**
 * Check if a color string is in the rgba() format and not fully opaque.
 * (alpha channel should be less than 1 is it exists)
 *
 * @param {String} color The color to check.
 *
 * @returns {Boolean}
 */
export const isColorRGBA = color => {
  color = color.toLowerCase();

  if (color.substring(0, 4) === 'rgba') {
    // rgba(0, 0, 0, 0) format.
    const matches = /^rgba\s*\((\d+),\s*(\d+),\s*(\d+),([^)]*)\)$/.exec(color);
    const alpha = matches[4];

    if (parseFloat(alpha) < 1) {
      return true;
    }

    return false;
  }

  return false;
};

/**
 * Get the current breakpoint value.
 *
 * @param {Object} theme The theme object.
 *
 * @returns {'xs'|'sm'|'md'|'lg'|'xl'|null}
 *
 */
export const getCurrentBreakpoint = theme => {
  const values = Object.values(theme.breakpoints.values).sort((prev, next) =>
    prev > next ? 1 : -1
  );

  let relevantValue = null;

  for (const value of values) {
    if (matchMedia(`(min-width: ${value}px)`).matches) {
      relevantValue = value;
    }
  }

  for (const breakpoint in theme.breakpoints.values) {
    if (theme.breakpoints.values[breakpoint] === relevantValue) {
      return breakpoint;
    }
  }
};
