import { useStaticQuery, graphql } from 'gatsby';

const useContactPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query ContactPageEntriesQuery {
        cms {
          entry(section: "contactPage") {
            ... on cms_contactPage_contactPage_Entry {
              title
              contactForm {
                ... on cms_contactForm_contactForm_BlockType {
                  id
                  typeHandle
                  contactFormCta
                  form {
                    ...formFields
                  }
                }
              }
              contactText {
                ... on cms_contactText_contactText_BlockType {
                  contactTitle
                  contactInfo
                }
              }
            }
          }
          seomatic(uri: "/kontakt-oss") {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default useContactPageEntry;
