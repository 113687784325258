import { useStaticQuery, graphql } from 'gatsby';

const useFooterEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query FooterEntriesQuery {
        cms {
          entries(section: "footer") {
            id
            ... on cms_footer_footer_Entry {
              id
              textBlocks {
                ...textBlocksFields
              }
              copyright
              linkGroups {
                ...linkGroupsFields
              }
            }
          }
        }
      }
    `
  );
  return cms.entries[0];
};

export default useFooterEntry;
