import React from 'react';
import styled, { css } from 'styled-components';
import Logo from '@assets/logo.svg';

const mobile = css`
  width: 67px;
  height: 16px;
`;

const menuVisible = css`
  g {
    fill: ${({ theme }) => theme.colors.white}!important;
  }
`;

export default styled(({ menuVisible, absolute: omitAbsolute, ...props }) => <Logo {...props} />)`
  display: block;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};

  g {
    fill: ${({ mode, theme }) => (mode === 'dark' ? theme.colors.white : theme.colors.dark)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }

  ${props => props.menuVisible && menuVisible}
`;
