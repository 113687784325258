import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

const overlay = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: background-color 0.3s linear;
    background-color: ${({ overlay }) => transparentize(1 - overlay.opacity / 100, overlay.color)};
  }
`;

export default styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  transition: background-color 0.3s linear;
  background-color: ${({ background }) => background};

  ${props => props.overlay && overlay}

  & iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
