import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import VisibilitySensorComponent from './VisibilitySensor.component';

const VisibilitySensorContainer = ({
  children,
  id,
  offsetTop,
  offsetBottom,
  disabled,
  onVisible,
  onHidden,
  forceHiddenInactiveState,
  ...props
}) => {
  const offset = useMemo(() => {
    if (typeof window === 'undefined') {
      return {};
    }

    const windowHalfHeight = window.innerHeight / 2;

    return {
      ...(offsetTop ? { top: windowHalfHeight } : {}),
      ...(offsetBottom ? { bottom: windowHalfHeight } : {}),
    };
  }, [offsetTop, offsetBottom]);

  const handleOnChange = useCallback(
    visible => {
      if (visible) {
        onVisible(id);
      } else {
        onHidden(id);
      }
    },
    [onVisible, onHidden, id]
  );

  if (disabled) return children;

  return (
    <VisibilitySensor partialVisibility offset={offset} {...props} onChange={handleOnChange}>
      {({ isVisible }) => (
        <VisibilitySensorComponent
          id={id}
          isVisible={!!isVisible}
          forceHiddenInactiveState={forceHiddenInactiveState}
        >
          {children}
        </VisibilitySensorComponent>
      )}
    </VisibilitySensor>
  );
};

export default VisibilitySensorContainer;

VisibilitySensorContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  offsetTop: PropTypes.bool,
  offsetBottom: PropTypes.bool,
  onVisible: PropTypes.func,
  onHidden: PropTypes.func,
  forceHiddenInactiveState: PropTypes.bool,
};

VisibilitySensorContainer.defaultProps = {
  disabled: false,
  offsetTop: false,
  offsetBottom: true,
  forceHiddenInactiveState: false,
  onVisible: () => {},
  onHidden: () => {},
};
