import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import ReactHtmlParser from 'react-html-parser';
import { getHtmlLang } from './SEO.helpers';

const SEO = ({ data }) => {
  if (data.asArray) {
    const metaTagContainer = JSON.parse(data.metaTagContainer),
      metaTitleContainer = JSON.parse(data.metaTitleContainer),
      metaLinkContainer = JSON.parse(data.metaLinkContainer),
      metaJsonLdContainer = JSON.parse(data.metaJsonLdContainer),
      metaScriptContainer = JSON.parse(data.metaScriptContainer);

    const title = metaTitleContainer.title.title;
    const meta = Object.values(metaTagContainer).filter(m => !Array.isArray(m));
    const link = Object.values(metaLinkContainer).filter(l => !Array.isArray(l));
    const jsonLd = Object.values(metaJsonLdContainer).map(s => ({
      type: 'application/ld+json',
      innerHTML: JSON.stringify(s),
    }));
    const scriptHeadTags = Object.values(metaScriptContainer)
      .filter(s => !!s.script)
      .map(s => ({
        type: 'text/javascript',
        innerHTML: s.script,
      }));
    // const scriptBodyTags = Object.values(metaScriptContainer).filter(s => !!s.bodyScript);

    return (
      <Helmet
        htmlAttributes={{
          lang: getHtmlLang(),
        }}
        title={title}
        meta={meta}
        link={link}
        script={[...jsonLd, ...scriptHeadTags]}
      />
    );
  }

  const scriptHeadTags = ReactHtmlParser(
    [data.metaJsonLdContainer, JSON.parse(data.metaScriptContainer).script],
    {
      transform: node => {
        if (node.type === 'script') {
          return {
            type: node.attribs.type || 'text/javascript',
            innerHTML: node.children?.[0]?.data,
          };
        }

        return null;
      },
    }
  ).filter(Boolean);

  return (
    <Helmet
      htmlAttributes={{
        lang: getHtmlLang(),
      }}
      script={scriptHeadTags}
    >
      {/* Inject raw HTML into head */}
      {ReactHtmlParser([data.metaTitleContainer, data.metaTagContainer, data.metaLinkContainer])}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  data: PropTypes.shape({
    asArray: PropTypes.bool,
    metaLinkContainer: PropTypes.string.isRequired,
    metaScriptContainer: PropTypes.string.isRequired,
    metaJsonLdContainer: PropTypes.string.isRequired,
    metaTagContainer: PropTypes.string.isRequired,
    metaTitleContainer: PropTypes.string.isRequired,
  }).isRequired,
};
