import React from 'react';
import { Container, Grid, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Link } from 'gatsby';
import { useFooterEntry } from '@hooks/cms';
import NavList from './NavList';
import TextBlock from './TextBlock';
import Copyright from './Copyright';

const Footer = () => {
  const footerEntry = useFooterEntry(),
    theme = useTheme(),
    isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer>
      <Box py={{ xs: 12.5, md: 26 }} bgcolor={theme.colors.blue}>
        <Container>
          <Grid container spacing={3}>
            {footerEntry.textBlocks.map(block => (
              <Grid item xs={12} md={6} key={block.id}>
                <TextBlock dangerouslySetInnerHTML={{ __html: block.text }} />
              </Grid>
            ))}
          </Grid>
          <Box mt={{ xs: 5, md: 20 }}>
            <Grid
              container
              alignItems={isSmall ? 'flex-start' : 'flex-end'}
              direction={isSmall ? 'column-reverse' : 'row'}
            >
              <Grid item xs={12} md={6}>
                <Copyright>{footerEntry.copyright}</Copyright>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={6}>
                  {footerEntry.linkGroups.map(group => (
                    <Grid item xs={6} md={4} key={group.id}>
                      <NavList title={group.linkGroupTitle}>
                        {group.linkGroupLinks.map(link => (
                          <li key={link.linkUrl}>
                            {link.linkUrl.charAt(0) === '/' ? (
                              <Link to={link.linkUrl}>{link.linkTitle}</Link>
                            ) : (
                              <a href={link.linkUrl} target='_blank' rel='noopener noreferrer'>
                                {link.linkTitle}
                              </a>
                            )}
                          </li>
                        ))}
                      </NavList>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};

export default Footer;
