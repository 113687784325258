import { useStaticQuery, graphql } from 'gatsby';

const useApproachPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query ApproachPageEntriesQuery {
        cms {
          entry(section: "approach") {
            ... on cms_approach_approach_Entry {
              hero {
                ...heroFields
              }
              articleContent {
                ...articleContentFields
              }
            }
          }
          seomatic(uri: "/tilnarming") {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default useApproachPageEntry;
