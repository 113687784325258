import React from 'react';
import { useColorMode } from '@context/ColorModeContext';
import StyledMenuToggle from './MenuToggle.styled';

const MenuToggle = props => {
  const [{ header }] = useColorMode();

  return (
    <StyledMenuToggle {...header} {...props}>
      <button>Toggle menu</button>
    </StyledMenuToggle>
  );
};

export default MenuToggle;
