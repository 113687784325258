import styled, { css } from 'styled-components';
import { display } from '@material-ui/system';

const mobile = css`
  ${props =>
    props.menuVisible &&
    css`
      &::before {
        transform: rotate(45deg) translate(5px, 5px);
      }

      &::after {
        transform: rotate(-45deg) translate(5px, -5px);
      }

      &::before,
      &::after {
        background: ${({ theme }) => theme.colors.white}!important;
      }
    `}
`;

export default styled.div`
  ${display}
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.appBar + 1};
  padding: 17px 12px;
  cursor: pointer;

  button {
    display: block;
    appearance: none;
    position: relative;
    width: 24px;
    height: 14px;
    background: transparent;
    border: 0;
    text-align: left;
    text-indent: -9999px;
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: 0;
      color: ${({ mode, theme }) => (mode === 'dark' ? theme.colors.white : theme.colors.dark)};
    }

    &::before,
    &::after {
      position: absolute;
      left: 0;
      display: block;
      content: '';
      background: ${({ mode, theme }) =>
        mode === 'dark' ? theme.colors.white : theme.colors.dark};
      width: 100%;
      height: 2px;
      transition-duration: 0.2s;
      transition-property: transform;
      transition-timing-function: linear;
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    ${({ theme }) => theme.breakpoints.down('sm')} {
      ${mobile}
    }
  }
`;
