import React, { useState, useCallback, useEffect } from 'react';
import Vimeo from '@u-wave/react-vimeo';
import { Fade } from '@material-ui/core';
import StyledBackground from './Background.styled';
import { useColorMode } from '@context/ColorModeContext';
import Picture from '@c/Picture';

const Background = () => {
  const [{ background: backgroundMode }] = useColorMode(),
    [videoLoaded, setVideoLoaded] = useState(false);

  useEffect(() => {
    if (!backgroundMode.video) {
      setVideoLoaded(false);
    }
  }, [backgroundMode.video]);

  const handleLoadVideo = useCallback(() => {
    setVideoLoaded(true);
  }, []);

  return (
    <StyledBackground
      background={backgroundMode.color || (backgroundMode.overlay && backgroundMode.overlay.color)}
      overlay={backgroundMode.overlay}
    >
      {backgroundMode.video && (
        <Fade in={videoLoaded} timeout={300}>
          <div>
            <Vimeo
              video={backgroundMode.video}
              autoplay
              loop
              muted
              background
              controls={false}
              showByline={false}
              showTitle={false}
              onPlay={handleLoadVideo}
            />
          </div>
        </Fade>
      )}
      {backgroundMode.image && (
        <Picture height='100vh' alt={backgroundMode.altText} {...backgroundMode.image} />
      )}
    </StyledBackground>
  );
};

export default Background;
