import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import StyledMenu from './Menu.styled';
import MenuToggle from './MenuToggle';
import MenuNav from './MenuNav';
import { Fade, useMediaQuery } from '@material-ui/core';

const Menu = ({ toggleMenuCallback }) => {
  const [menuVisible, setMenuVisible] = useState(false),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  useEffect(() => {
    toggleMenuCallback(menuVisible);
  }, [menuVisible, toggleMenuCallback]);

  return (
    <StyledMenu>
      <Fade in={isSmall} timeout={300}>
        <MenuToggle
          onClick={() => setMenuVisible(!menuVisible)}
          display={{ md: 'none' }}
          menuVisible={menuVisible}
        />
      </Fade>
      <Fade in={!isSmall || menuVisible} timeout={300}>
        <MenuNav />
      </Fade>
    </StyledMenu>
  );
};

export default Menu;

Menu.propTypes = {
  toggleMenuCallback: PropTypes.func,
};

Menu.defaultProps = {
  toggleMenuCallback: () => {},
};
