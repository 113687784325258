import React from 'react';
import { useColorMode } from '@context/ColorModeContext';
import StyledSkipLink from './SkipLink.styled';

const SkipLink = () => {
  const [{ header }] = useColorMode();

  return (
    <StyledSkipLink href='#content' {...header}>
      Hopp til hovedinnholdet
    </StyledSkipLink>
  );
};

export default SkipLink;
