import { useStaticQuery, graphql } from 'gatsby';

const useBuzzwordPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query BuzzwordPageEntriesQuery {
        cms {
          entry(section: "buzzword") {
            ... on cms_buzzword_buzzword_Entry {
              buzzwordIntro {
                ...buzzwordIntroFields
              }
              buzzwordContent {
                ...buzzwordContentFields
              }
            }
          }
          seomatic(uri: "/ordliste") {
            ...seomaticFields
          }
        }
      }
    `
  );

  return cms;
};

export default useBuzzwordPageEntry;
