import { useTheme } from '@material-ui/core/styles';
import { contrastRatio } from '@helpers';

const useHeaderModeByBackground = backgroundColor => {
  const theme = useTheme();

  if (
    contrastRatio(theme.colors.white, backgroundColor) >
    contrastRatio(theme.colors.dark, backgroundColor)
  ) {
    return 'dark';
  }

  return 'light';
};

export default useHeaderModeByBackground;
