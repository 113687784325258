import styled, { css } from 'styled-components';

const mobile = css`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.appBar};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;

  & > .MuiContainer-root {
    justify-content: center;
    text-align: center;
  }

  li {
    margin: ${({ theme }) => theme.spacing(3)}px 0;
    ${({ theme }) => theme.typography.h4}
  }
`;

const desktop = css`
  li {
    display: inline-block;
  }
  li + li {
    margin-left: ${({ theme }) => theme.spacing(6)}px;
  }
`;

export default styled.nav`
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.125rem;
    font-weight: 500;
  }

  li > a {
    text-decoration: none;
    color: inherit;
    z-index: 1;
    position: relative;
  }

  li > a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background: currentColor;
    visibility: hidden;
    border-radius: 5px;
    transform: scaleX(0);
    transition-duration: 0.2s;
    transition-property: visibility, transform;
    transition-timing-function: linear;
  }

  li > a:hover::before,
  li > a:focus::before,
  li > a.active::before {
    visibility: visible;
    transform: scaleX(1);
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${desktop}
  }
`;
