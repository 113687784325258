import styled from 'styled-components';

export default styled.a`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: ${({ mode, theme }) => (mode === 'dark' ? theme.colors.white : theme.colors.dark)};

  &:focus,
  &:active {
    position: fixed;
    left: 50%;
    top: ${({ theme }) => theme.spacing(2)}px;
    transform: translateX(-50%);
    width: auto;
    height: auto;
    overflow: auto;
  }
`;
