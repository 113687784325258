import React from 'react';
import StyledMain from './Main.styled';
import PropTypes from 'prop-types';

const Main = ({ children }) => <StyledMain>{children}</StyledMain>;

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
