import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Fade } from '@material-ui/core';
import StyledImage from './Image.styled';

const Image = React.forwardRef((props, ref) => {
  const innerRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (!ref) return;

    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  }, [ref, innerRef]);

  useEffect(() => {
    if (!imageLoaded && innerRef.current.complete) {
      setImageLoaded(true);
    }
  }, [imageLoaded, innerRef]);

  const handleLoadImage = useCallback(() => {
    setImageLoaded(true);
  }, []);

  return (
    <Fade in={imageLoaded} timeout={300}>
      <StyledImage onLoad={handleLoadImage} onError={handleLoadImage} ref={innerRef} {...props} />
    </Fade>
  );
});

export default Image;
