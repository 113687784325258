import styled, { css } from 'styled-components';

const mobile = css`
  h2 {
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  }

  p {
    font-size: ${({ theme }) => theme.typography.body1[theme.breakpoints.down('sm')].fontSize};
    line-height: ${({ theme }) => theme.typography.body1[theme.breakpoints.down('sm')].lineHeight};
  }

  a {
    padding-top: ${({ theme }) => theme.spacing(1.5)}px;
    padding-right: ${({ theme }) => theme.spacing(3.5)}px;
    padding-bottom: ${({ theme }) => theme.spacing(1.5)}px;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    margin-bottom: ${({ theme }) => theme.spacing(2)}px;

    &:hover {
      padding-right: ${({ theme }) => theme.spacing(4.5)}px;
    }

    &::after {
      right: ${({ theme }) => theme.spacing(2)}px;
    }

    &[href^='mailto:'] {
      font-size: 1.0625rem;
    }
  }
`;

export default styled.div`
  color: ${({ theme }) => theme.colors.white};

  h2 {
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }

  p {
    margin: 0;
    font-size: ${({ theme }) => theme.typography.body1.fontSize};
    font-weight: ${({ theme }) => theme.typography.body1.fontWeight};
    line-height: ${({ theme }) => theme.typography.body1.lineHeight};
  }

  a {
    display: inline-block;
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    padding-top: ${({ theme }) => theme.spacing(1.75)}px;
    padding-right: ${({ theme }) => theme.spacing(6.5)}px;
    padding-bottom: ${({ theme }) => theme.spacing(1.75)}px;
    padding-left: ${({ theme }) => theme.spacing(3)}px;
    border: solid 1px ${({ theme }) => theme.colors.white};
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 1.33;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: transparent;
      box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.white};
      padding-right: ${({ theme }) => theme.spacing(8)}px;

      &::after {
        margin-left: ${({ theme }) => theme.spacing(3)}px;
      }
    }

    &::after {
      content: '';
      width: 0.425rem;
      height: 0.425rem;
      position: absolute;
      border-top: solid 0.125rem ${({ theme }) => theme.colors.white};
      border-left: solid 0.125rem ${({ theme }) => theme.colors.white};
      transform: rotate(135deg);
      right: ${({ theme }) => theme.spacing(3.25)}px;
      top: 50%;
      margin-top: -3px;
      content: '';
    }

    &[href^='mailto:'] {
      display: block;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.1px;
      text-transform: none;
      text-decoration: underline;
      padding: 0;
      border: none;

      &::after {
        display: none;
      }

      &:hover {
        box-shadow: none;
        padding-: 0;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
