import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useColorMode } from '@context/ColorModeContext';

const useStyles = makeStyles(theme => ({
  root: ({ visibility }) => ({
    display: 'flex',
    flexDirection: 'column',
    transition: 'opacity 0.2s linear',
    opacity: visibility === 'visible' ? 1 : 0,
  }),
}));

const VisibilitySensorComponent = ({
  children,
  id,
  isVisible,
  forceHiddenInactiveState,
  ...props
}) => {
  const [
    {
      visibility: { hiddenContentVisible, visibleContentId },
    },
  ] = useColorMode();

  const visibility = useMemo(() => {
    if (hiddenContentVisible && !forceHiddenInactiveState) return 'visible';

    if (visibleContentId !== id) return 'hidden';

    return isVisible ? 'visible' : 'hidden';
  }, [hiddenContentVisible, forceHiddenInactiveState, isVisible, visibleContentId, id]);

  const classes = useStyles({ visibility });

  return <Box className={classes.root}>{children}</Box>;
};

export default VisibilitySensorComponent;

VisibilitySensorComponent.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired,
  forceHiddenInactiveState: PropTypes.bool.isRequired,
};
