import React, { useState, useCallback } from 'react';
import StyledHeader from './Header.styled';
import { Container, Fade } from '@material-ui/core';
import { Link } from 'gatsby';
import Menu from '@c/Menu';
import { useColorMode } from '@context/ColorModeContext';
import Logo from './Logo';

const Header = () => {
  const [{ header }] = useColorMode(),
    [menuVisible, setMenuVisible] = useState(false);

  const toggleMenuCallback = useCallback(
    menuOpen => {
      setMenuVisible(menuOpen);
    },
    [setMenuVisible]
  );

  return (
    <StyledHeader {...header}>
      <Container>
        <Fade in timeout={300}>
          <Link to={`/`}>
            <Logo {...header} menuVisible={menuVisible} />
          </Link>
        </Fade>
        <Menu toggleMenuCallback={toggleMenuCallback} />
      </Container>
    </StyledHeader>
  );
};

export default Header;
