import { useStaticQuery, graphql } from 'gatsby';

const useAboutPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query AboutPageEntriesQuery {
        cms {
          entry(section: "about") {
            ... on cms_about_about_Entry {
              hero {
                ...heroFields
              }
              articleContent {
                ...articleContentFields
              }
            }
          }
          seomatic(uri: "/om-oss") {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default useAboutPageEntry;
