import styled, { css } from 'styled-components';

const mobile = css`
  font-size: 0.625rem;
  line-height: 1.6;
  margin-top: ${({ theme }) => theme.spacing(8)}px;
`;

export default styled.p`
  font-size: 0.75rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  margin: 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
