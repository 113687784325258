import React from 'react';
import PropTypes from 'prop-types';
import SkipLink from '@c/SkipLink';
import Background from '@c/Background';
import SEO from '@c/SEO';
import Header from '@c/Header';
import Main from '@c/Main';
import Footer from '@c/Footer';

const Layout = ({ children, seomatic }) => (
  <>
    <SkipLink />
    <Background />
    <SEO data={seomatic} />
    <Header />
    <Main>{children}</Main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seomatic: PropTypes.shape({
    asArray: PropTypes.bool,
    metaLinkContainer: PropTypes.string.isRequired,
    metaScriptContainer: PropTypes.string.isRequired,
    metaJsonLdContainer: PropTypes.string.isRequired,
    metaTagContainer: PropTypes.string.isRequired,
    metaTitleContainer: PropTypes.string.isRequired,
  }).isRequired,
};

export default Layout;
