import { useStaticQuery, graphql } from 'gatsby';

const useFrontPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query FrontPageEntriesQuery {
        cms {
          entry(section: "frontPage") {
            ... on cms_frontPage_frontPage_Entry {
              hero {
                ...heroFields
              }
              articleContent {
                ...articleContentFields
              }
            }
          }
          seomatic(uri: "/") {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default useFrontPageEntry;
