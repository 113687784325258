import { useStaticQuery, graphql } from 'gatsby';

const useSelectedEntries = entries => {
  const { cms } = useStaticQuery(
    graphql`
      query SelectedEntriesQuery {
        cms {
          entries {
            ...caseBaseFields
            ...blogBaseFields
            ... on cms_clients_client_Entry {
              ...clientFields
            }
            ... on cms_careerListings_careerListing_Entry {
              ...careerListingFields
            }
          }
          blogMainPage: entry(section: "blogMainPage") {
            uri
          }
          casesMainPage: entry(section: "casesMainPage") {
            uri
          }
        }
      }
    `
  );

  return [
    entries.map(({ id }) => cms.entries.find(e => e.id === id)),
    cms.blogMainPage.uri,
    cms.casesMainPage.uri,
  ];
};

export default useSelectedEntries;
