import React from 'react';
import { Link } from 'gatsby';
import { Container, useMediaQuery } from '@material-ui/core';
import { useMenuLinks } from '@hooks/cms';
import StyledMenuNav from './MenuNav.styled';

const MenuNav = props => {
  const menuLinks = useMenuLinks(),
    isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <StyledMenuNav {...props}>
      <Container disableGutters={!isSmall}>
        <ul>
          {menuLinks.map(link => (
            <li key={link.id}>
              <Link to={link.linkUrl} partiallyActive activeClassName='active'>
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </StyledMenuNav>
  );
};

export default MenuNav;
