import { useStaticQuery, graphql } from 'gatsby';

const use404PageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query NotFoundPageEntriesQuery {
        cms {
          entry(section: "error404") {
            ... on cms_error404_error404_Entry {
              articleContent {
                ...articleContentFields
              }
            }
          }
          seomatic {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default use404PageEntry;
