import styled, { css } from 'styled-components';

const absolute = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

const mobile = css`
  height: ${({ theme }) => theme.header[theme.breakpoints.down('sm')].height}px;
`;

export default styled.header`
  display: flex;
  height: ${({ theme }) => theme.header.height}px;
  color: ${({ mode, theme }) => (mode === 'dark' ? theme.colors.white : theme.colors.dark)};
  background: transparent;

  .MuiContainer-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }

  ${props => props.absolute && absolute}
`;
