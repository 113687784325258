import { useStaticQuery, graphql } from 'gatsby';

const useCareerPageEntry = () => {
  const { cms } = useStaticQuery(
    graphql`
      query CareerPageEntriesQuery {
        cms {
          entry(section: "career") {
            ... on cms_career_career_Entry {
              hero {
                ...heroFields
              }
              articleContent {
                ...articleContentFields
              }
              mediaGrid {
                ...mediaGridFields
              }
              sellingPoints {
                ...sellingPointsFields
              }
              relatedEntries {
                ... on cms_relatedEntries_relatedEntries_BlockType {
                  relatedTitle
                  relatedEntries {
                    ... on cms_newsletterArticles_newsletterArticles_Entry {
                      uri
                      sectionHandle
                      relatedObjectHeader
                      relatedObjectDescription
                      featuredContent {
                        ...featuredContentFields
                      }
                    }
                  }
                }
              }
            }
          }
          seomatic(uri: "/karriere") {
            ...seomaticFields
          }
        }
      }
    `
  );
  return cms;
};

export default useCareerPageEntry;
