import { useStaticQuery, graphql } from 'gatsby';

const useLatestEntries = (sectionId, limit) => {
  const { cms } = useStaticQuery(
    graphql`
      query LatestEntriesQuery {
        cms {
          entries(orderBy: "postDate desc") {
            ...caseBaseFields
            ...blogBaseFields
            ... on cms_clients_client_Entry {
              ...clientFields
            }
            ... on cms_careerListings_careerListing_Entry {
              ...careerListingFields
            }
          }
          blogMainPage: entry(section: "blogMainPage") {
            uri
          }
          casesMainPage: entry(section: "casesMainPage") {
            uri
          }
        }
      }
    `
  );

  return [
    cms.entries.filter(entry => entry.sectionId === parseInt(sectionId)).slice(0, limit),
    cms.blogMainPage.uri,
    cms.casesMainPage.uri,
  ];
};

export default useLatestEntries;
