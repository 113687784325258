import { useStaticQuery, graphql } from 'gatsby';
const useMenuLinks = () => {
  const { cms } = useStaticQuery(
    graphql`
      query MenuLinksQuery {
        cms {
          entries(section: "mainNavigation") {
            ... on cms_mainNavigation_link_Entry {
              id
              title
              linkUrl
            }
          }
        }
      }
    `
  );
  return cms.entries;
};

export default useMenuLinks;
