import styled, { css } from 'styled-components';

const mobile = css`
  font-size: 0.75rem;

  & > li + li {
    margin-top: ${({ theme }) => theme.spacing(1.5)}px;
  }

  & > li > a::before {
    height: 1px;
    bottom: -1px;
  }
`;

export default styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.125rem;
  line-height: 1.33;
  color: ${({ theme }) => theme.colors.white};

  & > li + li {
    margin-top: ${({ theme }) => theme.spacing(2)}px;
  }

  & > li > a {
    text-decoration: none;
    color: inherit;
    z-index: 1;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background: currentColor;
      border-radius: 5px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
