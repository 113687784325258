import styled, { css } from 'styled-components';

const mobile = css`
  font-size: 0.625rem;
  line-height: 1.6;
  letter-spacing: 0.4px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export default styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.7;
  font-weight: bold;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${mobile}
  }
`;
