import * as Yup from 'yup';

export const handleSproutFieldType = sproutFieldType => {
  switch (sproutFieldType) {
    case 'hidden':
      return 'hidden';
    case 'email':
      return 'email';
    default:
      return 'text';
  }
};

export const validationMessages = {
  required: '* Obligatorisk',
  email: 'Ugyldig epost',
};

export const handleSproutFieldValidation = (sproutFieldType, required) => {
  switch (sproutFieldType) {
    case 'email':
      if (required) {
        return Yup.string().email(validationMessages.email).required(validationMessages.required);
      }
      return Yup.string().email(validationMessages.email);
    default:
      if (required) return Yup.string().required(validationMessages.required);
      return Yup.string();
  }
};

export const handleSproutFormValidationSchema = sproutFields =>
  Yup.object().shape({
    action: Yup.string().required(validationMessages.required),
    handle: Yup.string().required(validationMessages.required),
    ...sproutFields.reduce((acc, cur) => {
      acc[cur.attributes.handle] = handleSproutFieldValidation(
        cur.type,
        cur.attributes.required === '1'
      );
      return acc;
    }, {}),
  });

export const handleFormData = variables => {
  const formData = new FormData();

  Object.keys(variables).forEach(key => {
    switch (key) {
      case 'handle':
        formData.append('handle', variables[key]);
        break;
      case 'employee':
        formData.append(`fields[${key}][]`, variables[key]);
        break;
      default:
        formData.append(`fields[${key}]`, variables[key]);
        break;
    }
  });

  return formData;
};
