import { transparentize } from 'polished';
import { useTheme } from '@material-ui/core/styles';

const useHeroBackground = ({ backgroundColor, overlayOpacity, overlayColor }) => {
  const theme = useTheme();

  return (
    backgroundColor || transparentize(1 - overlayOpacity / 100, overlayColor || theme.colors.blue)
  );
};

export default useHeroBackground;
