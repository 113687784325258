import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import Image from '@c/Image';
import { useFilteredProps } from '@hooks/helpers';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => {
  return {
    picture: {
      display: 'block',
    },
  };
});

const Picture = React.forwardRef(
  (
    { xsUrl, smUrl, mdUrl, lgUrl, xlUrl, url, originalUrl, useOriginalImage, className, ...props },
    ref
  ) => {
    const theme = useTheme(),
      classes = useStyles(),
      filteredProps = useFilteredProps(props, ['title']);

    if (useOriginalImage && originalUrl) {
      return (
        <picture className={className}>
          <Image
            srcSet={`${originalUrl}`}
            ref={ref}
            width='auto'
            maxWidth='100%'
            mx='auto'
            {...filteredProps}
          />
        </picture>
      );
    }

    return (
      <picture className={`${classes.picture} ${className ? className : ''}`}>
        {xsUrl && (
          <source
            srcSet={`${xsUrl}`}
            media={`(max-width: ${theme.breakpoints.values.sm - 0.05}px)`}
          />
        )}
        {smUrl && (
          <source
            srcSet={`${smUrl}`}
            media={`(max-width: ${theme.breakpoints.values.md - 0.05}px)`}
          />
        )}
        {mdUrl && (
          <source
            srcSet={`${mdUrl}`}
            media={`(max-width: ${theme.breakpoints.values.lg - 0.05}px)`}
          />
        )}
        {lgUrl && (
          <source
            srcSet={`${lgUrl}`}
            media={`(max-width: ${theme.breakpoints.values.xl - 0.05}px)`}
          />
        )}
        {xlUrl && (
          <source srcSet={`${xlUrl}`} media={`(min-width: ${theme.breakpoints.values.xl}px)`} />
        )}
        {(url || originalUrl) && (
          <Image srcSet={`${url || originalUrl}`} ref={ref} {...filteredProps} />
        )}
      </picture>
    );
  }
);

export default Picture;

Picture.propTypes = {
  xsUrl: PropTypes.string,
  smUrl: PropTypes.string,
  mdUrl: PropTypes.string,
  lgUrl: PropTypes.string,
  xlUrl: PropTypes.string,
  url: PropTypes.string,
  originalUrl: PropTypes.string,
  useOriginalImage: PropTypes.bool,
  className: PropTypes.string,
};

Picture.defaultProps = {
  xsUrl: null,
  smUrl: null,
  mdUrl: null,
  lgUrl: null,
  xlUrl: null,
  url: null,
  originalUrl: null,
  useOriginalImage: false,
};
