import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import StyledNavList from './NavList.styled';

const NavList = ({ title, children }) => (
  <div>
    {title && <Title>{title}</Title>}
    <StyledNavList>{children}</StyledNavList>
  </div>
);

export default NavList;

NavList.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NavList.defaultProps = {
  title: null,
};
